import "./App.css";
import React, { useEffect } from "react";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import useTelegram from "./hooks/useTelegram";
import ProductList from "./components/Products/ProductList";
import ProductDetails from "./components/Products/ProductDetails";
import Greeting from "./components/Greeting/Greeting";
import Footer from "./components/Footer/Footer";
import OrderList from "./components/History/OrderList";
import OrderDetails from "./components/History/OrderDetails";
import { isAppApiLocal } from "./utils/getAppApiEnv";
import { authenticateUser, getAccessToken } from "./redux/authSlice";
import RedirectToProducts from "./components/RedirectToProducts/RedirectToProducts";

function App() {
  const dispatch = useDispatch();
  const { tg, raw, seller, queryId, date, hash, onToggleBackButton } =
    useTelegram();
  const accessToken = useSelector(getAccessToken);

  useEffect(() => {
    if (!isAppApiLocal()) {
      tg.expand();
      tg.ready();
      // tg.enableClosingConfirmation();
      tg.setHeaderColor("secondary_bg_color");
      tg.setBackgroundColor("secondary_bg_color");
      onToggleBackButton();
    }
  }, [tg, onToggleBackButton]);

  useEffect(() => {
    if (seller && !isAppApiLocal()) {
      const authenticate = async () => {
        const initData = {
          queryId,
          raw,
          seller,
          date,
          hash,
        };
        dispatch(authenticateUser(initData));
      };

      authenticate();
    }
  }, [dispatch, date, hash, queryId, seller, raw]);

  if (!accessToken && !isAppApiLocal()) {
    return <Greeting />;
  }

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<RedirectToProducts />} />
          <Route path="/products" element={<ProductList />} />
          <Route path="/products/:id" element={<ProductDetails />} />
          <Route path="/orders" element={<OrderList />} />
          <Route path="/orders/:id" element={<OrderDetails />} />
        </Routes>
      </BrowserRouter>
      <Footer />
    </div>
  );
}

export default App;
