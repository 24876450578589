import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { client } from '../api/client';
import { baseURL } from '../api/config';
import { isAppApiLocal } from '../utils/getAppApiEnv';

export const fetchOrderItemStatuses = createAsyncThunk('orderItemStatuses/fetchOrderItemStatuses', async ({ orderId, itemId }, { getState }) => {
  if (isAppApiLocal()) {
    const mockedResponse = await client.get('/mocks/orderItemStatuses.json');
    return mockedResponse.data.data;
  }
  const token = getState().auth.accessToken;
  const response = await client.get(`${baseURL}/orders/${orderId}/items/${itemId}/statuses`, token);
  return response.data.data;
});

export const addOrderItemStatus = createAsyncThunk('orderItemStatuses/addOrderItemStatus', async ({ orderId, itemId, statusId }, { getState }) => {
  const token = getState().auth.accessToken;
  const response = await client.post(`${baseURL}/orders/${orderId}/items/${itemId}/statuses/${statusId}`, null, token);
  return response.data;
});

export const orderItemStatusesSlice = createSlice({
  name: 'orderItemStatuses',
  initialState: {
    orderItemStatuses: [],
    selectedItemId: null,
    status: 'idle',
    error: null,
  },
  reducers: {
    clear: (state) => {
      state.orderItemStatuses = [];
      state.status = 'idle';
      state.selectedItemId = null;
      state.error = null;
    },
    setSelectedItem: (state, action) => {
      state.selectedItemId = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchOrderItemStatuses.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchOrderItemStatuses.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.orderItemStatuses = action.payload || [];
      })
      .addCase(fetchOrderItemStatuses.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export const getOrderItemStatuses = (state) => state.orderItemStatuses.orderItemStatuses || [];
export const getSelectedItemId = (state) => state.orderItemStatuses.selectedItemId;

export const { clear, setSelectedItem } = orderItemStatusesSlice.actions;
