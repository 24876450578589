/* eslint no-param-reassign: 0 */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { client } from '../api/client';
import { baseURL } from '../api/config';
import { isAppApiLocal } from '../utils/getAppApiEnv';

export const fetchOrders = createAsyncThunk('orders/fetchOrders', async (shopId, { getState }) => {
  if (isAppApiLocal()) {
    const mockedResponse = await client.get('/mocks/orders.json');
    return mockedResponse.data.data;
  }
  const token = getState().auth.accessToken;
  const requestUrl = shopId
    ? `${baseURL}/orders?shopId=${shopId}`
    : `${baseURL}/orders`;
  const response = await client.get(requestUrl, token);
  return response.data.data;
});

export const ordersSlice = createSlice({
  name: 'orders',
  initialState: {
    orders: [],
    status: 'idle',
    error: null,
  },
  reducers: {
    load: (state) => {
      state.orders = [];
    },
    clearOrders: (state) => {
      state.orders = [];
      state.status = 'idle';
      state.error = null;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchOrders.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchOrders.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.orders = action.payload || [];
      })
      .addCase(fetchOrders.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export const selectOrders = (state) => state.orders.orders || [];
export const getOrdersStatus = (state) => state.orders.status;

export const { load, clearOrders } = ordersSlice.actions;
