import React from 'react';
import {
  AppBar,
  Skeleton as MuiSkeleton,
  Toolbar,
  IconButton,
} from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIosRounded';
import PersonIcon from '@mui/icons-material/PersonOutlineRounded';
import LocationIcon from '@mui/icons-material/LocationOnRounded';
import WalletIcon from '@mui/icons-material/AccountBalanceWalletRounded';
import styles from './OrderDetails.module.css';
import itemStyles from './OrderDetailsItem.module.css';

function OrderDetailsSkeleton() {
  return (
    <div className={styles.orderDetailsContainer}>
      <AppBar
        position="fixed"
        sx={{
          backgroundColor: 'var(--tg-theme-header-bg-color)',
          boxShadow: 'none',
          height: '56px',
        }}
      >
        <Toolbar sx={{ height: '56px', display: 'flex', justifyContent: 'space-between' }}>
          <IconButton>
            <ArrowBackIosIcon className={styles.headerIcon} />
          </IconButton>
          <div className={styles.headerText}>
            <MuiSkeleton variant="text" width={100} sx={{ fontSize: '1.25rem', color: 'var(--tg-theme-text-color)' }} />
            <MuiSkeleton variant="text" width={100} sx={{ fontSize: '0.75rem', color: 'var(--tg-theme-hint-color)' }} />
          </div>
          <div className={styles.emptyDiv} />
        </Toolbar>
      </AppBar>
      <div className={styles.orderDetails}>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <MuiSkeleton variant="text" width={110} sx={{ fontSize: '1.5rem', color: 'var(--tg-theme-subtitle-text-color)' }} />
        </div>
        <div className={styles.recieverSection}>
          <div className={styles.shippingIconContainer}>
            <LocationIcon className={styles.icon} />
          </div>
          <div className={styles.shippingInfoContainer}>
            <MuiSkeleton variant="text" width={80} sx={{ fontSize: '0.75rem', color: 'var(--tg-theme-hint-color)' }} />
            <MuiSkeleton variant="text" width={200} sx={{ fontSize: '1.0rem', color: 'var(--tg-theme-text-color)' }} />
            <MuiSkeleton variant="text" width={100} sx={{ fontSize: '0.75rem', color: 'var(--tg-theme-hint-color)' }} />
          </div>
        </div>
        <div className={styles.shippingSection}>
          <div className={styles.shippingIconContainer}>
            <PersonIcon className={styles.icon} />
          </div>
          <div className={styles.shippingInfoContainer}>
            <MuiSkeleton variant="text" width={80} sx={{ fontSize: '0.75rem', color: 'var(--tg-theme-hint-color)' }} />
            <MuiSkeleton variant="text" width={200} sx={{ fontSize: '1.0rem', color: 'var(--tg-theme-text-color)' }} />
            <MuiSkeleton variant="text" width={100} sx={{ fontSize: '0.75rem', color: 'var(--tg-theme-hint-color)' }} />
          </div>
        </div>
        <div className={styles.totalSection}>
          <div className={styles.shippingIconContainer}>
            <WalletIcon className={styles.icon} />
          </div>
          <div className={styles.shippingInfoContainer}>
            <MuiSkeleton variant="text" width={50} sx={{ fontSize: '1.5rem', color: 'var(--tg-theme-text-color)' }} />
            <MuiSkeleton variant="text" width={50} sx={{ fontSize: '0.75rem', color: 'var(--tg-theme-hint-color)' }} />
          </div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <MuiSkeleton variant="text" width={200} sx={{ fontSize: '1.25rem', color: 'var(--tg-theme-subtitle-text-color)' }} />
        </div>
        <div className={styles.orderDetailsItems}>
          {([1, 2]).map((index) => (
            <div key={index}>
              <div className={itemStyles.orderDetailsItem}>
                <div className={itemStyles.imageContainer}>
                  <MuiSkeleton
                    variant="rectangular"
                    animation="wave"
                    width={100}
                    height={60}
                    sx={{
                      borderRadius: '8px',
                    }}
                  />
                </div>
                <div className={itemStyles.productDetails}>
                  <MuiSkeleton
                    variant="text"
                    width={200}
                    sx={{ fontSize: '1rem', color: 'var(--tg-theme-text-color)' }}
                  />
                  <MuiSkeleton
                    variant="text"
                    width={40}
                    sx={{ fontSize: '1.25rem', color: 'var(--tg-theme-text-color)' }}
                  />
                </div>
              </div>
              <div className={itemStyles.itemButtons}>
                {}
              </div>
            </div>
          ))}
        </div>
        <div className={styles.totalContainer}>
          <div>
            <MuiSkeleton
              variant="text"
              width={50}
              sx={{
                fontSize: '20px',
                marginLeft: '16px',
                textAlign: 'left',
                color: 'var(--tg-theme-text-color)',
              }}
            />
            <MuiSkeleton
              variant="text"
              width={55}
              sx={{
                fontSize: '0.875rem',
                marginLeft: '16px',
                textAlign: 'left',
                color: 'var(--tg-theme-text-color)',
              }}
            />
          </div>
          <div>
            <MuiSkeleton
              variant="text"
              width={50}
              sx={{
                fontSize: '1.5rem',
                marginRight: '16px',
                color: 'var(--tg-theme-text-color)',
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default OrderDetailsSkeleton;
