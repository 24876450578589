import React, { useState } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { Typography } from "@mui/material";
import styles from "./ProductCard.module.css";
import { noImageURL } from "../../api/config";
import useQueryParams from "../../hooks/useQuieryParams";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";

import "swiper/css";
import "swiper/css/pagination";

function ProductCard({ product }) {
  const [imageLoaded, setImageLoaded] = useState(false);
  const { shopId } = useQueryParams();
  const productPath = shopId
    ? `/products/${product.id}?shopId=${shopId}`
    : `/products/${product.id}`;

  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  return (
    <Link to={productPath} key={product.id} className={styles.link}>
      <div className={styles.productCard}>
        <div className={styles.imageContainer}>
          <Swiper pagination={true} modules={[Pagination]}>
            <SwiperSlide>
              <img
                src={
                  imageLoaded && product.imageUrl
                    ? product.imageUrl
                    : noImageURL
                }
                alt={product.title}
                onLoad={handleImageLoad}
              />
            </SwiperSlide>

            {product.mediaFiles.map((mediaFile, index) => (
              <SwiperSlide key={product.id + index}>
                <img
                  src={mediaFile.fileUrl || noImageURL}
                  alt={product.title}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>

        <div className={styles.productDetails}>
          <Typography variant="body2" className={styles.description}>
            {product.summary}
          </Typography>
          <Typography variant="h7" className={styles.price}>
            {`${product.price} ${product.currencySymbol}`}
          </Typography>
        </div>
      </div>
    </Link>
  );
}

ProductCard.propTypes = {
  product: PropTypes.shape({
    id: PropTypes.number.isRequired,
    imageUrl: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    summary: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    currencySymbol: PropTypes.string.isRequired,
  }).isRequired,
};

export default ProductCard;
