import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {
  Typography,
} from '@mui/material';
import styles from './OrderDetailsItem.module.css';
import { noImageURL } from '../../api/config';
import { fetchOrderItemStatuses, setSelectedItem, getSelectedItemId } from '../../redux/orderItemStatusesSlice';

function OrderDetailsItem({ item, orderId }) {
  const dispatch = useDispatch();
  const selectedItemId = useSelector(getSelectedItemId);

  const onItemClick = () => {
    dispatch(setSelectedItem(item.itemId));
    dispatch(fetchOrderItemStatuses({ orderId, itemId: item.itemId }));
  };

  return (
    <div className={classNames(
       selectedItemId === item.itemId ? styles.selectedItemWrapper : '',
      )}>
      <div className={styles.orderDetailsItem} onClick={onItemClick}>
        <div className={styles.imageContainer} >
          <img src={item.imageUrl || noImageURL} alt={item.title} />
        </div>
        <div className={styles.productDetails}>
          <Typography
            variant="body1"
            sx={{ color: 'var(--tg-theme-text-color)' }}
          >
            {item.summary}
          </Typography>
          <Typography
            variant="h6"
            sx={{ color: 'var(--tg-theme-text-color)' }}
          >
            {`${item.currencySymbol}${item.price}`}
          </Typography>
        </div>
      </div>
    </div>
  );
}

OrderDetailsItem.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number.isRequired,
    itemId: PropTypes.number.isRequired,
    imageUrl: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    summary: PropTypes.string.isRequired,
    currencySymbol: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    quantity: PropTypes.number.isRequired,
  }).isRequired,
  orderId: PropTypes.number.isRequired,
};

export default OrderDetailsItem;
