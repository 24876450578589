const tg = window.Telegram.WebApp;

export default function useTelegram() {
  const onClose = () => {
    tg.close();
  };
  const onToggleMainButton = () => {
    if (!tg.MainButton.isVisible) {
      tg.MainButton.show();
    } else {
      tg.MainButton.hide();
    }
  };
  const onToggleBackButton = () => {
    if (!tg.BackButton.isVisible) {
      tg.BackButton.show();
    } else {
      tg.BackButton.hide();
    }
  };
  return {
    onClose,
    onToggleMainButton,
    onToggleBackButton,
    tg,
    raw: tg?.initData,
    seller: tg?.initDataUnsafe?.user,
    queryId: tg?.initDataUnsafe?.query_id,
    date: tg?.initDataUnsafe?.auth_date,
    hash: tg?.initDataUnsafe?.hash,
    startParam: tg?.initDataUnsafe?.start_param,
    // cloudStorage: tg?.CloudStorage,
  };
}
