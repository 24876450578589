const baseURL = 'https://seller.aokichrk.com/api/v1';
// const baseURL = 'https://localhost:5231/api/v1';

const noImageURL = '/images/no_product.png';
// const lableURL = '/images/aokimarketlable.png';
// const lableURL = '/images/aokiseller.svg';
const lableURL = '/images/aokisellerlogo.svg';
const hieroglyph = '/images/Duang-Chinese.svg';

export { baseURL, noImageURL, lableURL, hieroglyph };
