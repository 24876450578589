import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { client } from '../api/client';
import { baseURL } from '../api/config';
import { isAppApiLocal } from '../utils/getAppApiEnv';

export const fetchOrder = createAsyncThunk('order/fetchOrder', async (id, { getState }) => {
  if (isAppApiLocal()) {
    const mockedResponse = await client.get('/mocks/order.json');
    return mockedResponse.data;
  }
  const token = getState().auth.accessToken;
  const response = await client.get(`${baseURL}/orders/${id}`, token);
  return response.data;
});

export const orderSlice = createSlice({
  name: 'order',
  initialState: {
    currentOrder: null,
    status: 'idle',
    error: null,
  },
  reducers: {
    clear: (state) => {
      state.currentOrder = null;
      state.status = 'idle';
      state.error = null;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchOrder.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchOrder.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.currentOrder = action.payload || [];
      })
      .addCase(fetchOrder.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export const selectCurrentOrder = (state) => state.order.currentOrder || null;
export const getCurrentOrderStatus = (state) => state.order.status;

export const { clear } = orderSlice.actions;
