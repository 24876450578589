import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { client } from '../api/client';
import { baseURL } from '../api/config';
import { isAppApiLocal } from '../utils/getAppApiEnv';

export const fetchProduct = createAsyncThunk('products/fetchProduct', async (id, { getState }) => {
  if (isAppApiLocal()) {
    const mockedResponse = await client.get('/mocks/product.json');
    return mockedResponse.data;
  }
  const token = getState().auth.accessToken;
  const response = await client.get(`${baseURL}/products/${id}`, token);
  return response.data;
});

export const deleteProduct = createAsyncThunk(
  'products/deleteProduct',
  async (id, { getState, rejectWithValue }) => {
    try {
      const token = getState().auth.accessToken;
      const response = await client.delete(`${baseURL}/products/${id}`, token);
      return response.data;
    } catch (error) {
      // Return error message using rejectWithValue
      console.error('Error deleting product:', error);
      return rejectWithValue(error.message);
    }
    // const token = getState().auth.accessToken;
    // const response = await client.delete(`${baseURL}/products/${id}`, token);
    // return response.data;
  }
);

export const productSlice = createSlice({
  name: 'product',
  initialState: {
    currentProduct: null,
    status: 'idle',
    error: null,
    deleteProductStatus: 'idle',
    deleteProductError: null,
  },
  reducers: {
    clear: (state) => {
      state.currentProduct = null;
      state.status = 'idle';
      state.error = null;
    },
    resetDeleteProductStatus: (state) => {
      state.deleteProductStatus = 'idle';
      state.deleteProductError = null;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchProduct.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchProduct.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.currentProduct = action.payload || [];
      })
      .addCase(fetchProduct.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(deleteProduct.pending, (state) => {
        state.deleteProductStatus = 'loading';
      })
      .addCase(deleteProduct.fulfilled, (state) => {
        state.deleteProductStatus = 'succeeded';
        state.currentProduct = null;
      })
      .addCase(deleteProduct.rejected, (state, action) => {
        state.deleteProductStatus = 'failed';
        state.deleteProductError = action.error.message;
      });
  },
});

export const selectCurrentProduct = (state) => state.product.currentProduct || null;
export const getCurrentProductStatus = (state) => state.product.status;
export const getDeleteProductStatus = (state) => state.product.deleteProductStatus;

export const { clear, resetDeleteProductStatus } = productSlice.actions;
