import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { client } from '../api/client';
import { baseURL } from '../api/config';

export const authenticateUser = createAsyncThunk('auth/authenticateUser', async (request) => {
  const response = await client.post(`${baseURL}/auth`, request);
  return response.data;
});

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    accessToken: null,
    // refreshToken: null,
    status: 'idle',
    error: null,
  },
  reducers: {
    load: (state) => {
      state.accessToken = null;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(authenticateUser.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(authenticateUser.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.accessToken = action.payload.accessToken;
        // state.refreshToken = action.payload.refreshToken;
      })
      .addCase(authenticateUser.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload?.message || 'Authentication failed';
      });
  },
});

export const getAccessToken = (state) => state.auth.accessToken;
// export const getRefreshToken = (state) => state.auth.refreshToken;
export const getAuthStatus = (state) => state.auth.status;
