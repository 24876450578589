import React from 'react';
import { lableURL } from '../../api/config';
// import { hieroglyph } from '../../api/config';
import styles from './Greeting.module.css';

function Greeting() {
  // const [textAnimationFinished, setTextAnimationFinished] = useState(false);
  // const [hieroglyphAnimationFinished, setHieroglyphAnimationFinished] = useState(false);

  // useEffect(() => {
  //   const spans = document.querySelectorAll(`.${styles.textContainer} span`);
  //   spans.forEach((span, index) => {
  //     span.style.setProperty('--i', index);
  //   });

  //   spans[spans.length - 1].addEventListener('animationend', handleTextAnimationEnd);
  //   return () => {
  //     spans[spans.length - 1].removeEventListener('animationend', handleTextAnimationEnd);
  //   };
  // }, []);
  // const handleTextAnimationEnd = () => {
  //   setTextAnimationFinished(true);
  // };
  // const handleHieroglyphAnimationEnd = () => {
  //   setHieroglyphAnimationFinished(true);
  // };

  // useEffect(() => {
  //   if (textAnimationFinished && hieroglyphAnimationFinished) {
  //     onAnimationComplete();
  //   }
  // }, [textAnimationFinished, hieroglyphAnimationFinished, onAnimationComplete]);

  return (
    <div className={styles.greetingContainer}>
      {/* {textAnimationFinished
        ? (
          <div className={styles.hieroglyphWrapper}>
            <img 
              src={hieroglyph}
              alt=""
              className={styles.fallingHieroglyph}
              onAnimationEnd={handleHieroglyphAnimationEnd}/>
          </div>
        )
        : <div className={styles.hieroglyphSpace} />
      }
      <div className={styles.textWrapper}>
        <Typography variant="h3" className={styles.textContainer}>
          <span>A</span>
          <span>O</span>
          <span>K</span>
          <span>I</span>
        </Typography>
        <Typography variant="h5" className={styles.textContainer}>
          <span>M</span>
          <span>a</span>
          <span>r</span>
          <span>k</span>
          <span>e</span>
          <span>t</span>
        </Typography>
      </div> */}
      <img className={styles.greetingImg} src={lableURL} alt="" />
    </div>
  );
}

export default Greeting;
