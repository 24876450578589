/* eslint no-param-reassign: 0 */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { client } from '../api/client';
import { baseURL } from '../api/config';
import { isAppApiLocal } from '../utils/getAppApiEnv';

export const fetchProducts = createAsyncThunk('products/fetchProducts', async (shopId, { getState }) => {
  if (isAppApiLocal()) {
    const mockedResponse = await client.get('/mocks/products.json');
    return mockedResponse.data.data;
  }
  const token = getState().auth.accessToken;
  const requestUrl = shopId
    ? `${baseURL}/products?shopId=${shopId}`
    : `${baseURL}/products`;
  const response = await client.get(requestUrl, token);
  return response.data.data;
});

export const productsSlice = createSlice({
  name: 'products',
  initialState: {
    products: [],
    status: 'idle',
    error: null,
  },
  reducers: {
    load: (state) => {
      state.products = [];
    },
    clearProducts: (state) => {
      state.products = [];
      state.status = 'idle';
      state.error = null;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchProducts.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchProducts.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.products = action.payload || [];
      })
      .addCase(fetchProducts.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export const selectAllProducts = (state) => state.products.products || [];
export const getProductsStatus = (state) => state.products.status;

export const { load, clearProducts } = productsSlice.actions;
