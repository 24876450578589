import React, { useState } from "react";
import { Typography, IconButton } from "@mui/material";
import ContentCopyRoundedIcon from "@mui/icons-material/ContentCopyRounded";
import styles from "./Footer.module.css";
import useTelegram from "../../hooks/useTelegram";

function Footer() {
  const addressToCopy = "bc1qfwgdm55706g9l5f9mcewwpa4nqn22vvpd5d7zx";
  const [isClicked, setIsClicked] = useState(false);
  const { seller } = useTelegram();
  const username = `${seller?.first_name} ${seller?.last_name}`;

  const handleCopyClick = async () => {
    setIsClicked(true);
    await navigator.clipboard.writeText(addressToCopy);
  };

  return (
    <div className={styles.appFooter}>
      <Typography variant="body1" className={styles.footerTitle}>
        {username}
      </Typography>
      <div className={styles.footerTitle}>
        <Typography variant="body2">Do not send bitcoin here</Typography>
        <IconButton onClick={handleCopyClick}>
          <ContentCopyRoundedIcon
            style={{
              cursor: "pointer",
              color: isClicked
                ? "var(--tg-theme-subtitle-text-color)"
                : "var(--tg-theme-link-color)",
              fontSize: "1rem",
              zIndex: "1",
            }}
          />
        </IconButton>
      </div>
      <Typography variant="body1" className={styles.footerTitle}>
        &copy; 2024 AOKI Seller
      </Typography>
    </div>
  );
}

export default Footer;
