
export async function client(endpoint, { body, method, ...customConfig } = {}) {
    const headers = { 'Content-Type': 'application/json' };
  
    const config = {
      method,
      ...customConfig,
      headers: {
        ...headers,
        ...customConfig.headers,
      },
    };
  
    if (body) {
      config.body = JSON.stringify(body);
    }
  
    let data;
    try {
      const response = await window.fetch(endpoint, config);
  
      if (response.ok) {
        const hasBody = response.headers.get('content-length') !== '0';
        // Return a result object similar to Axios
        return {
          status: response.status,
          data: hasBody ? await response.json() : null,
          headers: response.headers,
          url: response.url,
        };
      }
      throw new Error(`Request failed with status: ${response.statusText}`);
    } catch (error) {
      return Promise.reject(error.message ? error.message : data);
    }
  }
  
  const getAuthorizationHeader = (token) => ({
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  
  client.get = function (endpoint, token, customConfig = {}) {
    return client(
      endpoint,
      {
        ...customConfig,
        method: 'GET',
        ...token && getAuthorizationHeader(token),
      },
    );
  };
  
  client.post = function (endpoint, body, token, customConfig = {}) {
    return client(
      endpoint,
      {
        ...customConfig,
        body,
        method: 'POST',
        ...token && getAuthorizationHeader(token),
      },
    );
  };
  
  client.put = function (endpoint, body, token, customConfig = {}) {
    return client(
      endpoint,
      {
        ...customConfig,
        body,
        method: 'PUT',
        ...token && getAuthorizationHeader(token),
      },
    );
  };
  
  client.delete = function (endpoint, token, customConfig = {}) {
    return client(
      endpoint,
      {
        ...customConfig,
        method: 'DELETE',
        ...token && getAuthorizationHeader(token),
      },
    );
  };
  
  