import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { client } from '../api/client';
import { baseURL } from '../api/config';

export const postProductToChannel = createAsyncThunk(
  'channels/postProductToChannel',
  async (productId, { getState }) => {
    try {
      const token = getState().auth.accessToken;
      const response = await client.post(`${baseURL}/channels/products/${productId}`, null, token);
      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  }
);

const initialState = {
  postProductStatus: 'idle',
  postProductError: null,
};

export const channelSlice = createSlice({
  name: 'channel',
  initialState,
  reducers: {
    resetPostProductStatus: (state) => {
      state.postProductStatus = 'idle';
      state.postProductError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(postProductToChannel.pending, (state) => {
        state.postProductStatus = 'loading';
        state.postProductError = null;
      })
      .addCase(postProductToChannel.fulfilled, (state) => {
        state.postProductStatus = 'succeeded';
        state.postProductError = null;
      })
      .addCase(postProductToChannel.rejected, (state, action) => {
        state.postProductStatus = 'failed';
        state.postProductError = action.error.message;
      });
  },
});

export const { load, resetPostProductStatus } = channelSlice.actions;
export const getPostProductStatus = (state) => state.channel.postProductStatus;
export const getPostProductError = (state) => state.channel.postProductError;

export default channelSlice.reducer;
