import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Paper,
  Typography,
  Button,
} from '@mui/material';
import styles from './About.module.css';

function About({ product }) {
  const MAX_HEIGHT_ROWS = 4; // Maximum number of rows to display initially
  const [expanded, setExpanded] = useState(false);
  const [showMoreButton, setShowMoreButton] = useState(false);
  const textContainerRef = useRef(null);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  
  useEffect(() => {
    if (textContainerRef.current) {
      const container = textContainerRef.current;
      setShowMoreButton(container.scrollHeight > container.clientHeight);
    }
  }, [product.description]);

  return (
    <Paper
      className={styles.paper}
      sx={{
        boxShadow: 'none',
        backgroundColor: 'var(--tg-theme-section-bg-color)',
      }}
    >
      <Typography variant="h5" sx={{ color: 'var(--tg-theme-section-header-text-color)' }}>About</Typography>
      <Typography
        variant="body2"
        className={`${styles.details} ${expanded ? styles.expandedText : ''}`}
        ref={textContainerRef}
        style={{
          maxHeight: expanded ? 'none' : `calc(${MAX_HEIGHT_ROWS} * 1.2em)`,
          overflow: 'hidden',
          color: 'var(--tg-theme-text-color)',
        }}
      >
        {product.description}
      </Typography>
      {showMoreButton && (
        <Button
          onClick={handleExpandClick}
          type="button"
          sx={{
            color: 'var(--tg-theme-hint-color)',
            fontSize: '8px',
            alignSelf: 'flex-end',
            borderRadius: '10px',
          }}
        >
          {expanded ? 'Show less ▲' : 'Show more ▼'}
        </Button>
      )}
    </Paper>
  );
}

About.propTypes = {
  product: PropTypes.shape({
    description: PropTypes,
  }).isRequired,
};

export default About;
